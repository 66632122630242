import bsc from "../assests/images/bsc.png"
import avax from "../assests/images/Avalanche.png"
import arbitrum from "../assests/images/arb.png"
import eth from "../assests/images/eth.png"
import zkSync from "../assests/images/zkSync.png"

export const MAX_UNIT256 = '115792089237316195423570985008687907853269984665640564039457584007913129639935'

export const SWAP_FEE = 9975 //99.75%
export const TRADING_VOLUME_24 = 50 //
export const ALLOWED_PRICE_IMPACT_LOW = 1 //1%
// Percent = new Percent(JSBI.BigInt(100), BIPS_BASE) // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM = 3 //3%
// Percent = new Percent(JSBI.BigInt(300), BIPS_BASE) // 3%
export const ALLOWED_PRICE_IMPACT_HIGH = 5  //5%
// Percent = new Percent(JSBI.BigInt(500), BIPS_BASE) // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN = 10 //10%
// Percent = new Percent(JSBI.BigInt(1000), BIPS_BASE) // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT = 15 //15%
// Percent = new Percent(JSBI.BigInt(1500), BIPS_BASE) // 15%

export const ONEDAYINSECONDS = 86400;
export const frontendurl = window.location.origin;

export const userFee = 5

export const MAX_HOPS = 3

export const CHAINS = [

    {
        NAME: "BNB Test Chain",
        RPC_URL: "https://bsc-testnet-rpc.publicnode.com",
        SYMBOL: "BNB",
        IMAGE: bsc,
        CHAIN_ID: 97,
        TRANSACTION: "https://testnet.bscscan.com/tx",
        TokenTracker: "https://testnet.bscscan.com/token/",
        Testnet: "https://testnet.bscscan.com/address",
        EndBlock: "https://testnet.bscscan.com/block/countdown"

    },
    {
        NAME: "BNB Chain",
        RPC_URL: "https://bsc-dataseed.binance.org/",
        SYMBOL: "BNB",
        IMAGE: bsc,
        CHAIN_ID: 56,
        TRANSACTION: "https://bscscan.com/tx",
        TokenTracker: "https://bscscan.com/token/",
        Testnet: "https://bscscan.com/address",
        EndBlock: "https://bscscan.com/block/countdown"

    },
    // {
    //     NAME: "Avalanche",
    //     RPC_URL:"https://api.avax.network/ext/bc/C/rpc",
    //     CHAIN_ID:43114,
    //     SYMBOL:"AVAX",
    //     IMAGE: avax,
    //     TRANSACTION : "https://snowtrace.io/tx/",
    //     TokenTracker : "https://snowtrace.io/token/",
    //     Testnet : "https://snowtrace.io/address/",
    //     EndBlock : "https://snowtrace.io/block/countdown"
    // },
    // {
    //     NAME: "Ethereum",
    //     RPC_URL:"https://rpc2.sepolia.org",
    //     CHAIN_ID:11155111,
    //     IMAGE: eth,
    //     TRANSACTION : "https://sepolia.etherscan.io/tx/",
    //     TokenTracker : "https://sepolia.etherscan.io/token/",
    //     Testnet : "https://sepolia.etherscan.io/address/", 
    //     EndBlock : "https://sepolia.etherscan.io/block/countdown"

    // },
    {
        NAME: "Avalanche Test",
        RPC_URL: "https://api.avax-test.network/ext/bc/C/rpc",
        CHAIN_ID: 43113,
        SYMBOL: "AVAX",
        IMAGE: avax,
        TRANSACTION: "https://testnet.snowtrace.io/tx/",
        TokenTracker: "https://testnet.snowtrace.io/token/",
        Testnet: "https://testnet.snowtrace.io/address/",
        EndBlock: "https://testnet.snowtrace.io/block/countdown"
    },
    // {
    //     NAME: "Arbitrum",
    //     RPC_URL:"https://arb1.arbitrum.io/rpc",
    //     CHAIN_ID:421614,
    //     IMAGE: arbitrum,
    //     TRANSACTION : "https://arbiscan.io/tx/",
    //     TokenTracker : "https://arbiscan.io/token/", 
    //     Testnet : "https://arbiscan.io/address/",
    //     EndBlock : "https://arbiscan.io/block/countdown"
    // },
    // {
    //     NAME: "zkSync",
    //     RPC_URL:"https://testnet.era.zksync.dev",
    //     CHAIN_ID:280,
    //     IMAGE: zkSync,
    //     TRANSACTION : "https://explorer.zksync.io/tx/",
    //     TokenTracker : "https://explorer.zksync.io/token/",
    //     Testnet : "https://explorer.zksync.io/address/",
    //     EndBlock : "https://explorer.zksync.io/block/countdown"
    // },

];

//local
// export const FRONT_URL = "http://localhost:3000"
// export const ANALYTICS_URL = "http://localhost:3001"
// export const API_URL = "http://localhost:7097"

//demo
// export const FRONT_URL = "https://donutswapreact.maticz.in"
// export const ANALYTICS_URL = "https://donutswap.maticz.in"
export const API_URL = "https://donutswap-backend.maticz.in/"


//staging
// export const FRONT_URL = "https://stage.donutswap.finance"
// export const ANALYTICS_URL = "https://stageanalytics.donutswap.finance"

//live
export const FRONT_URL = "https://donutswap.finance"
export const ANALYTICS_URL = "https://analytics.donutswap.finance"

export const TOKEN_FEE = 0.01;

export const ZEROTH_ADDRESS = "0x0000000000000000000000000000000000000000"

// 1 day, 7 days, 30 days, 1 year, 5 years
export const DAYS_TO_CALCULATE_AGAINST = [1, 7, 30, 365, 1825]

export const MINIMUM_LIQUIDITY = 0.000000000000001

export const PROJECT_ID = "b8a1daa2dd22335a2fe1d2e139980ae0"

export const BURN = "0x000000000000000000000000000000000000dEaD";

// export const BLOCKS_PER_YEAR = 10512000;

export const BSC_BLOCK_TIME = 3

export const BLOCKS_PER_YEAR = (60 / BSC_BLOCK_TIME) * 60 * 24 * 365 // 10512000

export const INTERVAL = 300; // 5 minutes
